ion-content {
  ion-card {
    img {
      height: 200px;
      object-fit: cover;
      width: 100%;
    }
  }
  ion-toolbar {
    --background: translucent;
  }
}
